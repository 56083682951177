/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/understand-differences-between-hearing-aid-technology-levels/'], ['en', 'https://www.hear.com/resources/hearing-aids/understand-differences-between-hearing-aid-technology-levels/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/understand-differences-between-hearing-aid-technology-levels/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aids/understand-differences-between-hearing-aid-technology-levels/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "understand-the-differences-between-hearing-aid-technology-levels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understand-the-differences-between-hearing-aid-technology-levels",
    "aria-label": "understand the differences between hearing aid technology levels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understand the Differences Between Hearing Aid Technology Levels"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever heard the saying, “it’s what’s on the inside that counts”?\nAnd when it comes to medical-grade hearing aids, this couldn’t be truer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aid manufacturers offer several different styles (the outside) and levels of hearing aid technology (the inside). These devices have come a long way, especially in the past 5 years. Every hearing aid from the leading brands is more discreet and more effective than ever before."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "style-considerations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#style-considerations",
    "aria-label": "style considerations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Style considerations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the outside, it’s easy to see that hearing aids come in many different shapes and styles. The two main categories of hearing aids are “in-the-ear” and “behind-the-ear”. Determining the appropriate style often depends on the severity of your hearing loss and the anatomy of your ear. Although some people really want “in-the-ear” devices, they aren’t always a viable solution. The size or shape of the ear canal might not be big enough for an in-the-ear device. The good news is that many “behind-the-ear” devices are now smaller, sleeker and practically invisible. They’re often rechargeable and come with additional features like Bluetooth, which actually makes them a more attractive option."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just look at all the people wearing Apple AirPods® or other Bluetooth wearables these days. Although a discreet style is always a consideration, we’ve found that determining the appropriate level of technology and functionality are the most important factors in long-term success."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "technology-is-what-matters-most",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#technology-is-what-matters-most",
    "aria-label": "technology is what matters most permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Technology is what matters most"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we classify every hearing aid into one of the technology levels below. We base these classifications on how you will be using hearing aids. Here are some general rules:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you’re only using the computer to surf the internet and correspond with email at home… you probably only need the essentials."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "However, if you’re storing pictures, streaming movies, playing games and often on-the-go… you’ll need something more advanced with a larger hard drive, faster processing, and more features."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "technology-levels-explained",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#technology-levels-explained",
    "aria-label": "technology levels explained permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Technology levels explained"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we classify every hearing aid into one of the technology levels below. We base these classifications on how you will be using hearing aids. Here are some general rules:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Technology levels are linked to the software (programs and algorithms) more than to the hardware (microphones, receivers, casing, etc)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "As you increase the technology level you increase the number of algorithms, programs, and advanced features that automatically enhance speech, provide natural sound, and reduce background noise in a variety of challenging environments."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Higher technology levels include more channels allowing your hearing care specialist to fine-tune your hearing aids, leading to a more customized sound experience."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "We always recommend two hearing aids vs. one to take advantage of binaural processing (enabling two hearing aids to communicate) which allows you to locate sound. If budget is an issue, it’s better to get two devices with a lower technology level than one higher-tech device."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "All the different technology levels are available in all the different hearing aid styles."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Even the lowest medical-grade hearing aid is better than a generic hearing amplifier or not wearing one at all."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/web-pricing-chart-ca.jpg",
    alt: "hear.com plan chart",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For budget-conscious customers, we do have an Economy option. These devices have basic speech clarity and natural sound for the simplest, quietest environments. These basic medical-grade hearing aids outperform all generic amplifiers or outdated technology. The Economy line of hearing aids ranges from $59-$109 per month*."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "which-hearing-aid-technology-level-is-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-hearing-aid-technology-level-is-right-for-you",
    "aria-label": "which hearing aid technology level is right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which hearing aid technology level is right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that you have all this information, our recommendation is always “to get as much technology as you can afford.” From all our interactions with customers, advanced programs and added conveniences will greatly increase your chances of hearing success. Speech will be clearer, the sound will be more natural and your life will be easier… even in more challenging environments. The best technology really does makes a difference. If you haven’t already signed up for our 30-day trial, click the button below to experience it for yourself."), "\n", React.createElement(ButtonCta, {
    copy: "Start your 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
